<script setup>
import { cn } from "@/lib/utils";
import { useVModel } from "@vueuse/core";

const props = defineProps({
    defaultValue: { type: [String, Number], required: false },
    modelValue: { type: [String, Number], required: false },
    class: { type: null, required: false },
    prefix: { type: String, required: false },
});

const emits = defineEmits(["update:modelValue"]);

const modelValue = useVModel(props, "modelValue", emits, {
    passive: true,
    defaultValue: props.defaultValue,
});
</script>

<template>
    <div class="border-0 p-0">
        <div class="relative rounded-md shadow-sm">
            <div
                v-show="prefix"
                class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
            >
                <span class="text-gray-500 sm:text-sm">{{ prefix }}</span>
            </div>
            <input
                v-model="modelValue"
                v-bind="$attrs"
                class="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                :class="cn(`${prefix ? 'pl-6 pr-3' : 'px-3'}`, props.class)"
            />
        </div>
    </div>
</template>
